/**
 * Important Notice:
 *
 * If Scripts like select2, owlCarousel or fancybox are needed,
 * the have to be moved from libs_notUsed to libs. Actually only
 * the flexnav files are in the folder libs.
 */

jQuery(function($) {

    /* tabElement */

    $('.tabs').before('<div class="tabBar"></div>');

    $('.tabElement .tabTitle').each(function(index, el){
        $(this).clone().appendTo('.tabBar').addClass('tab');
    });

    $('.tabElement dt').on('click', function() {
        $(this).toggleClass('open');
        $(this).next('dd').slideToggle();
    });

    $('.tabBar .tab').on('click', function() {
        $(".tabBar .tabTitle").removeClass('active');
        $(this).toggleClass('active');
        $('.tabs dd').hide();
        $('.tabs dd').eq($(this).index()).show();
    });

    enquire.register("screen and (min-width:320px)", {

        match : function() {
            $(".tabElement dd").hide();
            //$(".tabElement dt").first().addClass('open');
        },

    });

    enquire.register("screen and (min-width:1000px)", {

        match : function() {
            $(".tabElement dd").hide().first().show();
            $(".tabBar .tabTitle").first().addClass('active');
        },

        unmatch : function() {
            $(".tabElement dd").hide();
            $(".tabElement dt").removeClass('open');
            $(".tabBar .tabTitle").removeClass('active');
        },

    });

    /* country select */

      if ($('#partner').length) {

        $("#partner select").select2({
            placeholder: 'Select an option',
            minimumResultsForSearch: Infinity,
            width: "style"
        });

        $('#partner select').on('select2:select', function (e) {
            document.forms['partner'].submit();
        });

      }

    /* file field in form */

    $('input[type=file]').on('change', function() {
    var filename = $(this).val().split('\\').pop();
    if (filename)
      $(this).parent().parent().find('label').html(filename).addClass('selected');
    else
      $(this).parent().parent().find('label').html('').removeClass('selected');
    });


    /* form select 2 */

    $(".wpcf7 select").select2({
        placeholder: '---',
        minimumResultsForSearch: Infinity,
        width: "style",
    });

    /* productFilter */

    if ($('.productFilter').length) {
        $('.productFilter').each(function(index, el){
            $('#product').val($('#productName').text());
        });

        $('.productFilter .box').on('click', function() {
            $previews = $(this).closest('.selectionWrapper').find('.image');
            $surface_id = $(this).data('surface_id');

            // toggle surface preview
            $previews.eq($(this).index()).removeClass('hidden').siblings().addClass('hidden');

            // toggle surface active class
            $(this).addClass('active').siblings().removeClass('active');

            // show profiles wrapper
            $('.profiles.hidden').removeClass('hidden');

            // show specific profiles for selected surface
            $profiles = $('.profiles .selectionWrapper');

            $profiles.filter(function(){
                return $(this).data('surface_id') === $surface_id;
            }).removeClass('hidden').siblings('.selectionWrapper').addClass('hidden');

            // a surface was clicked
            if( $(this).closest('.surfaces').length ) {
                // scroll to profiles if clicked surface
                $('html,body').animate({scrollTop:$('.profiles').offset().top}, 500);
                // fill surface form field
                $("#surface").val($(this).find('figcaption').text());
                // clear profile form field
                $("#profile").val('');
            }

            // a profile was clicked
            if( $(this).closest('.profiles').length ) {
                // initial show of form
                $('.formWrapper').removeClass('hidden');
                // scroll to form if clicked profile
                $('html,body').animate({scrollTop:$('.formWrapper').offset().top}, 500);
                // fill profile form field
                $("#profile").val($(this).find('figcaption').text());
            }
        });

        $('.productFilter .box').on('mouseenter', function() {
            if( !$(this).hasClass('active') ) {
                $previews = $(this).closest('.selectionWrapper').find('.image');

                // hide all previews and show hovered one
                $previews.hide().eq($(this).index()).show();
            }
        });

        $('.productFilter .box').on('mouseleave', function() {
            if( !$(this).hasClass('active') ) {
                $previews = $(this).closest('.selectionWrapper').find('.image');

                // hide hovered preview again
                $previews.eq($(this).index()).hide();

                // show previously visible preview
                $previews.not('.hidden').show();
            }
        });
    }

    /* productSlider */
    initProductSlider();

});


function initProductSlider() {
    const productSlider = document.querySelectorAll('.productSlider');

    // Checking if there are any accordions on the page
    if (productSlider.length === 0) {
        return; // Exit the function if no accordions are found
    }

    productSlider.forEach(element => {
        var swiper = new Swiper(element.querySelector('.swiperThumbs'), {
            spaceBetween: 10,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesProgress: true,
        });

        // init swiper2
        var swiper2 = new Swiper(element.querySelector('.swiperFull'), {
            spaceBetween: 10,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            thumbs: {
                swiper: swiper,
            },
        });
    });
}
